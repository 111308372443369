import React, { Fragment } from "react";

const FourOhFourPage = () => {
  return <Fragment>
    <div className="d-flex justify-content-center align-items-center">
      <p className="font-weight-bold">Hey - That page doesn't exist!!!</p>
    </div>
    </Fragment>;
};

export default FourOhFourPage;
